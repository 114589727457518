import React from "react"
import { graphql } from "gatsby"

import Navigation from "../components/navigation"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Tag from "../components/tag"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const social = data.site.siteMetadata.social

  return (
    <Layout location={location} title={siteTitle} social={social}>
      <Seo title="Workshop: Kružić Križić" />
      <Navigation />
      <h1>Workshop: Kružić Križić</h1>
      <p>Ovdje možete pronaći sve potrebno za kružić križić workshop</p>
      <Tag
        tag={"Početni Kod"}
        external={true}
        link={"https://jsfiddle.net/0487ywv9/4/"}
      />
      <br />
      <Tag
        tag={"Pomoćni Dokument"}
        external={true}
        link={
          "https://docs.google.com/document/d/1oMMC_ncYDVY4WjCi_u-o3_k7Sw10O_zEQtv6I9V9zCY/edit?usp=sharing"
        }
      />
      <br />
      <Tag
        tag={"Prezentacija"}
        external={true}
        link={
          "https://docs.google.com/presentation/d/1zsvttKOSYC0lKiDuI8NXWiv4-0RTCnAmHZMHKi2h-dA/edit?usp=sharing"
        }
      />
      <br />
      <Tag
        tag={"Finalni Kod"}
        external={true}
        link={"https://jsfiddle.net/0487ywv9/2/"}
      />
      <br />
      <br />
      <hr />
      Molim vas da na kraju workshopa ispunite{" "}
      <Tag tag={"Post Workshop Form"} link={"/post"} />, kako biste na mail
      dobili vaš kod i više informacija, gdje i što dalje, te pomoć prilikom
      završetka "domaćeg" rada.
      <br />
      <br />
      <hr />
      Posebne zahvale Marco Dorfligeru zbog koda i ideje workshopa koji je bio
      prvi put održan u HUB385.
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          title
          url
        }
      }
    }
  }
`
