import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const TagContainer = styled.span`
  font-size: 12px;
  background: #1abc9c;
  padding: 2px;
  margin: 0 2px;
  a {
    color: white;
    text-decoration: none;
    box-shadow: none;
  }
`;

const Tag = ({tag, link, external = false}) => {
  return (
    <TagContainer>
    { !external && <Link to={link}> {tag} </Link> }
    { external && <a rel="noopener noreferrer" target="_BLANK" href={link}> {tag} </a> }
    </TagContainer>
  )
}

export default Tag
